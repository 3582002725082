const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6934720",
    space_js: "//cwflhpb.chenglsw.com/common/je/source/dm/production/waf/t/common/k.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934721",
    space_js: "//cwflhpb.chenglsw.com/source/kf_e_nx/production/bg_uo.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934722",
    space_js: "//cwflhpb.chenglsw.com/site/l/gf-oy/production/chv/common/v.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934723",
    space_js: "//cwflhpb.chenglsw.com/production/k_fe/common/nx/static/bgxl.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "",
    space_js: "",
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
